<template>
  <div>
    <!-- <Test /> -->
    <ag-grid-vue
      :autoSizeStrategy="{
        type: 'fitGridWidth',
        defaultMinWidth: 100,
        columnLimits: [
          {
            colId: 'containerNo',
            minWidth: 125,
          },
          {
            colId: 'carrierReferenceNumber',
            minWidth: 150,
          },
          {
            colId: 'stockSealNo',
            minWidth: 125,
          },
          {
            colId: 'noPallets',
            minWidth: 75,
            width: 75,
          },
          {
            colId: 'noCartons',
            minWidth: 75,
            width: 75,
          },
          {
            colId: 'customerContainerRef',
            minWidth: 125,
          },
        ],
      }"
      style="width: 100%;"
      :columnDefs="columns"
      :rowData="data"
      :getRowId="getRowId"
      :defaultColDef="defaultColDef.def"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */

import { AgGridVue } from "ag-grid-vue";
import ActionRenderer from "./ContainerComponents/Actions.vue";
export default {
  name: "Containers",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    refreshTableKey: {
      type: Number,
    },
  },
  components: {
    ActionRenderer,
    AgGridVue,
  },
  data: () => ({
    activeSources: [],
    availableSources: [],
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },

    columns: [],
    gridApi: null,
    invoiceItem: {},
    invoiceModal: false,
    loadingDataSource: false,
    savingContainer: false,
    savingDataSource: false,
    savingDeckNo: false,
    savingInvoice: false,
    sealLoader: false,
    selectedContainer: {},
    sealStockDialog: false,
    shipmentData: {},
    sourceItem: {},
    sourceModal: false,
  }),
  watch: {
    refreshTableKey(val) {
      if (val) {
        this.refreshTable();
      }
    },
    "shipment.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.columns = [
            {
              headerName: "Action",
              sortable: false,
              filter: false,
              cellRenderer: "ActionRenderer",
              cellRendererParams: {
                shipment: this.shipment,
                refreshContainer: this.refreshContainer,
                refreshContainers: this.$emit("refreshContainers"),
                refreshContainerContent: this.refreshContainerContent,
              },
            },
            {
              headerName: "Container No",
              field: "containerNo",
            },
            {
              headerName: "Client Ref",
              field: "customerContainerRef",
            },
            {
              headerName: "Carrier Ref",
              field: "carrierReferenceNumber",
              valueGetter: (p) => p.data.booking.carrierReferenceNumber,
              //       getQuickFilterText: params => {
              //     return params.data.booking.carrierReferenceNumber
              // }
            },
            { headerName: "CTO No", field: "ctoNo", align: "center" },
            {
              headerName: "Seal No",
              field: "sealNo",
            },
            {
              headerName: "Stock Seal",
              field: "stockSealNo",
            },
            {
              headerName: "Nett(KG)",
              field: "nettWeight",
              valueGetter: (p) => this.containerNettWeight(p.data),
            },
            {
              headerName: "Gross(KG)",
              field: "grossWeight",
              valueGetter: (p) => this.containerGrossWeight(p.data),
            },
            {
              headerName: "Pallets",
              field: "noPallets",
              valueGetter: (p) =>
                this.calculateUniquePallets(p.data.containerPallets),
            },
            {
              headerName: "Units",
              field: "noCartons",
              valueGetter: (p) =>
              p.data.containerPallets && p.data.containerPallets.length>0 ? this.calculateCartons(p.data.containerPallets):
              this.calculateCartonProducts(p.data.containerProducts),
            },
            {
              headerName: "Transporter",
              field: "transporterName",
            },
          ];
        }
      },
    },
  },
  methods: {
    addContainerProduct() {
      this.containerProduct = {
        shipmentId: this.shipment.id,
      };
      this.containerProductModal = true;
    },
    calculateUniquePallets(pallets) {
      return [...new Set(pallets.map((item) => item.sscc))].length;
    },
    calculateCartons(pallets) {
      let result = pallets.filter((item) => item.noCartons).map((item) => parseInt(item.noCartons));
      return result && result.length > 0 ? result.reduce((a, b) => a + b) : 0;
    },
    calculateCartonProducts(products) {
      let result = products.filter((item) => item.quantity).map((item) => parseInt(item.quantity));
      return result && result.length > 0 ? result.reduce((a, b) => a + b) : 0;
    },
    calculateActualGrossWeight(pallets) {
      return pallets
        .map((item) => (parseFloat(item.grossWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0);
    },
    calculateActualNettWeight(pallets) {
      return pallets.map((item) => item.nettWeight).reduce((a, b) => a + b, 0);
    },
    checkDataSourceChanges() {
      this.dataSourceChange = false;
      for (let i = 0; i < this.availableSources.length; i++) {
        let find = this.activeSources.findIndex(
          (x) => x.stockFileId == this.availableSources[i].id
        );
        if (this.availableSources[i].active && find == -1) {
          this.dataSourceChange = true;
          break;
        } else if (!this.availableSources[i].active && find > -1) {
          this.dataSourceChange = true;
          break;
        }
      }
      this.dataSourceKey++;
    },
    containerGrossWeight(container) {
      if (container.containerPallets.length > 0) {
        return container.containerPallets
          .filter((x) => x.grossWeight)
          .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        return container.containerProducts
          .filter((x) => x.grossWeight)
          .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    containerNettWeight(container) {
      if (container.containerPallets.length > 0) {
        return container.containerPallets
          .filter((x) => x.nettWeight)
          .map((item) => parseFloat(item.nettWeight))
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        return container.containerProducts
          .filter((x) => x.nettWeight)
          .map((item) => Math.round(parseFloat(item.nettWeight)))
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    openContainer(container) {
      this.$router.push({
        path: "/container/" + container.id + "/" + this.shipment.id,
      });
    },
    refreshContainer(id) {
      this.$emit("refreshContainer", id);
    },
    refreshContainerContent(id) {
      this.$emit("refreshContainerContent", id);
    },
    refreshTable() {
      if (this.data) {
        for (let item of this.data) {
          if (this.gridApi) {
            const rowNode = this.gridApi.getRowNode(item.id);
            if (rowNode) {
              rowNode.setData(item);
              this.gridApi.refreshCells({ rowNodes: [rowNode] });
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
</style>