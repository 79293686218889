var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ag-grid-vue',{staticStyle:{"width":"100%"},attrs:{"autoSizeStrategy":{
      type: 'fitGridWidth',
      defaultMinWidth: 100,
      columnLimits: [
        {
          colId: 'containerNo',
          minWidth: 125,
        },
        {
          colId: 'carrierReferenceNumber',
          minWidth: 150,
        },
        {
          colId: 'stockSealNo',
          minWidth: 125,
        },
        {
          colId: 'noPallets',
          minWidth: 75,
          width: 75,
        },
        {
          colId: 'noCartons',
          minWidth: 75,
          width: 75,
        },
        {
          colId: 'customerContainerRef',
          minWidth: 125,
        },
      ],
    },"columnDefs":_vm.columns,"rowData":_vm.data,"getRowId":_vm.getRowId,"defaultColDef":_vm.defaultColDef.def,"domLayout":"autoHeight"},on:{"grid-ready":_vm.onGridReady}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }