<template>
  <div>
    <v-card flat>
      <v-toolbar flat dense>
        <v-toolbar-title>
          <v-icon class="mr-2">handshake</v-icon>
          Shipment Profile: {{ profile.systemReference }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8">
            <v-list
              dense
              subheader
              :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'"
            >
              <v-subheader
                class="mt-0 pt-0 primaryText--text"
                style="font-size: 16px"
              >
                <v-icon left color="grey" class="mr-2">groups</v-icon> Assigned
                Parties
                <v-spacer></v-spacer>
                <v-checkbox
                  v-model="profile.letterOfCredit"
                  disabled
                  label="Letter of Credit Required"
                  id="checkbox-credit-letter"
                ></v-checkbox>
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="(party, index) in partyTypes"
                :key="index"
                style="border-bottom: 0.4px solid #5c5c5c"
              >
                <v-list-item-action style="width: 75px">
                  <v-col cols="12" class="text-center my-0 py-0">
                    <v-icon small>{{ party.icon }}</v-icon>
                    <br />
                    <span style="font-size: 9px; font-weight: bold">{{
                      party.name
                    }}</span>
                  </v-col>
                </v-list-item-action>
                <v-list-item-content v-if="profile[party.value]">
                  <v-list-item-title style="font-size: 12px">
                    {{ profile[party.value].name }}
                    <!-- <v-chip
                      class="ml-2"
                      label
                      x-small
                      v-if="
                        profile[party.value] &&
                        party.allowLink &&
                        !profile[party.linkedValue] &&
                        profile.editable
                      "
                      ><v-icon small left>add</v-icon>
                      {{ selectedOBOShipper }}
                    </v-chip> -->
                  </v-list-item-title>

                  <v-list-item-subtitle
                    style="font-size: 11px; color: grey"
                    v-if="
                      profile[party.value + 'AddressId'] ||
                      profile[party.value + 'AddressDescription']
                    "
                    class="text-wrap"
                  >
                    <v-icon class="mr-1" small>location_on</v-icon>
                    <span v-if="profile[party.value + 'AddressDescription']">{{
                      profile[party.value + "AddressDescription"]
                    }}</span>
                    <span v-else>{{
                      concatenateAddress(profile[party.value + "Address"])
                    }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    style="font-size: 12px"
                    v-if="profile[party.value] && profile[party.linkedValue]"
                  >
                    On Behalf of
                    <a>{{ profile[party.linkedValue].name }}</a>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    style="font-size: 12px"
                    v-if="
                      party.value == 'consignee' &&
                      profile.letterOfCredit &&
                      profile.bank
                    "
                  >
                    To the Order of
                    <a>{{ profile.bank.name }}</a></v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    style="font-size: 11px; color: grey"
                    v-if="
                      (profile[party.linkedValue] &&
                        profile[party.linkedValue + 'AddressId']) ||
                      profile[party.linkedValue + 'AddressDescription']
                    "
                    class="text-wrap"
                  >
                    <v-icon class="mr-1" small>location_on</v-icon>
                    <span
                      v-if="profile[party.linkedValue + 'AddressDescription']"
                      >{{
                        profile[party.linkedValue + "AddressDescription"]
                      }}</span
                    >
                    <span v-else>{{
                      concatenateAddress(profile[party.linkedValue + "Address"])
                    }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <span style="font-size: 12px; color: grey">
                    No {{ party.name }} assigned
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card flat id="distribution-list">
          <v-card-text class="my-0 py-0">
            <v-list dense subheader>
              <v-subheader style="font-size: 14px">
                <v-icon small class="mr-2">email</v-icon>
                Distribution List  <v-spacer></v-spacer>
            <v-tooltip top v-if="distributionList.length > 0">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="copyDistributionList()"><v-icon small>content_copy</v-icon></v-btn>

              </template>
              <span>Copy All</span>
            </v-tooltip></v-subheader>
            <v-divider></v-divider>
              <v-list-item v-if="distributionList.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey;font-size: 12px">No email addresses</span>
                </v-list-item-content></v-list-item>

              <v-list-item v-for="email in distributionList" :key="email.index" style="height: 30px">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">{{
                    email.email
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="3000" absolute bottom color="success" center text style="z-index: 99999;">
      Copied to clipboard!
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    distributionList: [],
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
    ],
    loadingDistributionList: false,
    snackbar: false,
  }),
  watch: {
    'profile.id': {
      immediate: true,
      async handler(val) {
        if(val){
          this.loadingDistributionList = true
      this.distributionList = await this.$API.getConsigneeProfileDistributionList(this.profile.id)
      this.loadingDistributionList = true
        }
      }
    }
  },
  methods: {
    copyDistributionList() {
      let emails = this.distributionList.map(x => x.email).join(';')
      navigator.clipboard.writeText(emails);
      this.snackbar = true;
    },
    constructAddressLine(addressLine, address) {
      if (address) {
        switch (addressLine) {
          case 1:
            return [address.addressLine1, address.addressLine2]
              .filter(Boolean)
              .join(", ");
          case 2:
            return [address.addressLine3, address.addressLine4]
              .filter(Boolean)
              .join(", ");
          case 3:
            return [
              address.town,
              address.subdivision,
              address.country,
              address.postalCode,
            ]
              .filter(Boolean)
              .join(", ");
        }
      }
    },
    concatenateAddress(address) {
      let result = [
        this.constructAddressLine(1, address),
        this.constructAddressLine(2, address),
        this.constructAddressLine(3, address),
      ];
      return result.filter(Boolean).join(", ");
    },
  },
};
</script>