<template>
  <div>
    <v-tooltip top v-if="shipment.breakBulkShipment">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          @click="editDeckNumberModal(item)"
          style="text-transform: none"
          icon
          ><v-icon small>edit</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px">Edit Deck Number</span>
    </v-tooltip>
    <v-tooltip
      top
      v-if="item && item.containerPallets && item.containerPallets.length > 0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          id="view-content"
          v-bind="attrs"
          v-on="on"
          @click="openContainerManagement(item)"
          style="text-transform: none"
          icon
          ><v-icon small>edit</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px">View Container Contents</span>
    </v-tooltip>

    <v-tooltip
      top
      v-if="item && item.containerPallets && item.containerPallets.length > 0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          id="view-content"
          v-bind="attrs"
          v-on="on"
          @click="openContainer(item)"
          style="text-transform: none"
          icon
          ><v-icon small>launch</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px">View Container Contents</span>
    </v-tooltip>
    <v-tooltip top v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          id="view-content"
          v-bind="attrs"
          v-on="on"
          @click="viewContainerModal(item)"
          style="text-transform: none"
          icon
          ><v-icon small>launch</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px">View Container</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="invoiceDetails(item)"
          style="text-transform: none"
          icon
          :color="item.invoiceNo ? 'success' : 'orange'"
          small
          ><v-icon small>monetization_on</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px" v-if="!item.invoiceNo"
        >No invoice details</span
      >
      <span style="font-size: 12px" v-else
        >Invoice: {{ item.invoiceNo }} {{ item.invoiceDate }}</span
      >
    </v-tooltip>
    <v-dialog
      v-model="deckModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="container">
        <v-card-title>
          Edit Deck Number
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="saveDeckNumber"
            color="secondary"
            icon
            :loading="savingDeckNo"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="deckModal = false">X</v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ container.ctoNo }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            label="Deck No."
            v-model="container.containerNo"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="invoiceModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Invoice <v-spacer></v-spacer>
          <v-btn text @click="(invoiceModal = false), (invoiceItem = {})"
            >X</v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ invoiceItem.containerNo }} - {{ invoiceItem.ctoNo }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            label="Invoice No."
            outlined
            dense
            clearable
            v-model="invoiceItem.invoiceNo"
          >
          </v-text-field>
          <v-text-field
            label="Invoice Date"
            outlined
            dense
            clearable
            v-model="invoiceItem.invoiceDate"
          >
          </v-text-field>
          <v-row justify="center" class="mt-2">
            <v-btn
              color="primary"
              :loading="savingInvoice"
              text
              @click="saveInvoice"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sealStockDialog" width="20vw" persistent>
      <v-card>
        <v-card-title>
          Edit Stock Seal No
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="updateStockSealNo"
            color="primary"
            :loading="sealLoader"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="sealStockDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Stock Seal No"
            v-model="selectedContainer.stockSealNo"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="containerProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="containerProduct">
        <Products
          @close="(containerProductModal = false), (containerProduct = {})"
          :item="containerProduct"
          @deleted="deleteSelectedContainerProduct"
          @chosenProduct="updateSelectedContainerProduct"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="containerModal"
      width="95vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card v-if="container">
        <v-toolbar flat>
          {{ container.ctoNo }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            @click="saveContainer"
            :loading="savingContainer"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="(containerModal = false), (container = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-list dense>
                <v-text-field
                  outlined
                  dense
                  label="Container No."
                  v-model="container.containerNo"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Seal No."
                  v-model="container.sealNo"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Customer Ref"
                  v-model="container.customerContainerRef"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Gross Weight"
                  v-model="container.grossWeight"
                  type="number"
                  step="0.01"
                  suffix="KG"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Nett Weight"
                  v-model="container.nettWeight"
                  type="number"
                  step="0.01"
                  suffix="KG"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense subheader v-if="container.containerProducts">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px"
                  >Cargo/Products
                  <v-btn icon color="primary" @click="addContainerProduct">
                    <v-icon>add_circle_outline</v-icon></v-btn
                  >
                  <!-- <v-spacer></v-spacer> -->
                </v-subheader>
                <v-divider></v-divider>

                <v-list-item
                  v-if="
                    !container.containerProducts ||
                    (container.containerProducts &&
                      container.containerProducts.length == 0)
                  "
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No products listed</span>
                  </v-list-item-content></v-list-item
                >
                <v-list-item
                  v-for="(cargo, index) in container.containerProducts"
                  :key="index"
                  @click="editContainerProduct(cargo)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-if="cargo.product">
                      {{ cargo.product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ cargo.hsCode }}
                      <span v-if="cargo.pallets">
                        - {{ cargo.pallets }} PALLETS</span
                      >
                      <span v-if="cargo.quantity">
                        - {{ cargo.quantity }} {{ cargo.quantityType }}</span
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="
                        cargo.industrial || cargo.organic || cargo.hazardous
                      "
                      class="text-wrap"
                    >
                      <v-chip
                        small
                        color="blue-grey"
                        class="mx-1"
                        v-if="cargo.industrial"
                        ><v-icon small left>factory</v-icon> Industrial
                        Use</v-chip
                      >
                      <v-chip
                        small
                        color="green"
                        class="mx-1"
                        v-if="cargo.industrial"
                        ><v-icon small left>spa</v-icon> Organic</v-chip
                      >
                      <v-chip
                        small
                        color="green"
                        class="mx-1"
                        v-if="cargo.hazardous"
                        ><v-icon color="orange" left>warning</v-icon>
                        Hazardous</v-chip
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="
                        cargo.grossWeight || cargo.nettWeight || cargo.volume
                      "
                      class="text-wrap"
                    >
                      NETT {{ cargo.nettWeight }} KG - GROSS
                      {{ cargo.grossWeight }} KG - {{ cargo.volume }} CBM
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px" :key="dataSourceKey">
                  <v-icon color="secondary" class="mr-2">bar_chart</v-icon>
                  Data Source(s)
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="savingDataSource"
                    @click="confirmDataSourceChange()"
                    color="blue"
                    icon
                  >
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingDataSource"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
                <!-- <small style="color:grey" v-if="$store.state.user.tagId == 3">
                                        You do not have permission to change the data source, please contact your System Administrator for assistance.
                                        </small> -->
                <v-list-item
                  v-for="source in availableSources"
                  :key="source.id"
                  style="border-bottom: 0.5px solid grey"
                >
                  <v-list-item-content
                    @click="viewSource(source)"
                    style="cursor: pointer"
                  >
                    <v-list-item-title>
                      {{ source.type }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="source.locationCode">
                      <v-icon class="mr-2" color="grey" small
                        >location_on</v-icon
                      >
                      {{ source.locationCode }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="source.transactionTime">
                      <v-icon class="mr-2" color="grey" small>schedule</v-icon>
                      {{ source.transactionTime }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap">
                      <v-icon class="mr-2" color="grey" small
                        >description</v-icon
                      >{{ source.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      color="success"
                      v-model="source.active"
                      @change="checkDataSourceChanges()"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-if="!loadingDataSource && availableSources.length == 0"
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No data sources found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sourceModal" fullscreen persistent>
      <v-card v-if="sourceModal">
        <v-card-title>
          Manage Data Source
          <v-spacer></v-spacer>
          <v-btn text @click="(sourceModal = false), (selectedContainer = null)"
            >X</v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ sourceItem.name }}
          <br />
          <v-icon class="mr-2" small color="grey">label</v-icon>
          {{ sourceItem.type }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12">
            </v-col> -->
            <v-col cols="12" v-if="sourceItem.sourceType == 'stockFile'">
              <v-data-table
                :items="sourceItem.contents"
                :headers="importHeaders"
                height="82vh"
                fixed-header
                dense
                disable-pagination
                hide-default-footer
              >
              </v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-list dense>
                    <v-subheader style="font-size: 16px"
                      >Containers</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item
                      v-for="container in sourceItem.stockFileContainers"
                      :key="container.id"
                      @click="selectedContainer = container"
                      :style="{
                        'border-left':
                          selectedContainer &&
                          selectedContainer.id == container.id
                            ? '3px solid var(--v-primary-base)'
                            : '',
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ container.containerNo }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-data-table
                    v-if="selectedContainer"
                    :items="selectedContainer.contents"
                    :headers="importHeaders"
                    height="82vh"
                    fixed-header
                    dense
                    disable-pagination
                    hide-default-footer
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSourceChangeModal" width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Confirm Data Source Change</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="confirmSourceChangeModal = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <p>
            Are you sure you want to change the data source for this container?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmSourceChangeModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="confirmTaskReset()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDocumentChangeModal" width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Refresh Tasks</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="confirmDocumentChangeModal = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <p>
            Would you like to refresh the subtasks linked to this container?
          </p>
          <v-row justify="center" class="mt-5 mb-3" v-if="!existingPallets">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="updateDataSource(false)"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="updateDataSource(true)"
              :loading="savingDataSource"
              >Yes</v-btn
            >
          </v-row>
          <v-row justify="center" class="mt-5 mb-3" v-else>
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="resetTasks(false)"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="resetTasks(true)"
              :loading="savingDataSource"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="containerManagementModal" fullscreen>
      <ContainerManagement
        :container="container"
        :shipment="shipment"
        @close="containerManagementModal = false"
        @containerSourceChanged="containerSourceChanged"
        @refreshContainer="refreshContainer"
      />
    </v-dialog>
  </div>
</template>
  
  <script>
import axios from "axios";
import ContainerManagement from "./ContainerManagement.vue";
import Products from "../../Bookings/Dialogs/ProductsDialog.vue";
export default {
  components: {
    ContainerManagement,
    Products,
  },
  data: () => ({
    availableSources: [],
    confirmSourceChangeModal: false,
    confirmDocumentChangeModal: false,
    container: {},
    containerManagementModal: false,
    containerModal: false,
    containerProductModal: false,
    containerProduct: {},
    dataSourceChange: false,
    dataSourceKey: 100,
    item: {},
    deckModal: false,
    existingPallets: false,
    invoiceItem: {},
    invoiceModal: false,
    loadingDataSource: false,
    savingContainer: false,
    savingDataSource: false,
    savingDeckNo: false,
    savingInvoice: false,
    sealStockDialog: false,
    sealLoader: false,
    selectedContainer: {},
    sourceItem: {},
    sourceModal: false,
    shipment: {},
  }),
  mounted() {
    this.item = this.params.data;
    this.shipment = this.params.shipment;
  },
  methods: {
    addContainerProduct() {
      this.containerProduct = {
        shipmentId: this.shipment.id,
      };
      this.containerProductModal = true;
    },
    checkDataSourceChanges() {
      this.dataSourceChange = false;
      for (let i = 0; i < this.availableSources.length; i++) {
        let find = this.activeSources.findIndex(
          (x) => x.stockFileId == this.availableSources[i].id
        );
        if (this.availableSources[i].active && find == -1) {
          this.dataSourceChange = true;
          break;
        } else if (!this.availableSources[i].active && find > -1) {
          this.dataSourceChange = true;
          break;
        }
      }
      this.dataSourceKey++;
    },
    confirmDataSourceChange() {
      this.confirmSourceChangeModal = true;
    },
    confirmTaskReset() {
      this.confirmSourceChangeModal = false;
      // this.confirmDocumentChangeModal = true;
      this.updateDataSource(false)
    },
    onEdit() {
      this.$emit("edit", this.params.data);
    },
    onDelete() {
      this.$emit("delete", this.params.data);
    },
    async deleteSelectedContainerProduct() {
      let find = this.container.containerProducts.findIndex(
        (x) => x.id == this.containerProduct.id
      );
      if (find > -1) {
        this.container.containerProducts.splice(find, 1);
      }
      this.containerProductModal = false;
      this.$message.success("Successfully removed product!");
    },
    editContainerProduct(product) {
      this.containerProduct = product;
      this.containerProduct.shipmentId = this.shipment.id;
      this.containerProductModal = true;
    },
    editDeckNumberModal(item) {
      this.container = item;
      this.deckModal = true;
    },
    editStockSealNo(item) {
      this.selectedContainer = item;
      this.sealStockDialog = true;
    },
    async getAvailableSources() {
      this.loadingDataSource = true;
      let result = await this.$API.getAvailableStockSources(this.container.id);
      this.activeSources = await this.$API.getActiveStockSources(
        this.container.id
      );
      for (let i = 0; i < result.length; i++) {
        let find = this.activeSources.find(
          (x) => x.source == result[i].type && x.stockFileId == result[i].id
        );
        result[i].active = find ? true : false;
        if (result[i].sourceType == "stockFile") {
          if (typeof result[i].contents == "string") {
            let data = await axios.get(result[i].contents);
            if (data && data.data) {
              result[i].contents = data.data;
            }
          }
        } else if (result[i].sourceType == "stockFileContainer") {
          for (let j = 0; j < result[i].stockFileContainers.length; j++) {
            if (typeof result[i].stockFileContainers[j].contents == "string") {
              let data = await axios.get(
                result[i].stockFileContainers[j].contents
              );
              if (data && data.data) {
                result[i].stockFileContainers[j].contents = data.data;
              }
            }
          }
        }
      }
      this.availableSources = result;
      this.loadingDataSource = false;
    },
    invoiceDetails(item) {
      this.invoiceItem = item;
      this.invoiceModal = true;
    },
    openContainer(container) {
      this.$router.push({
        path: "/container/" + container.id + "/" + this.shipment.id,
      });
    },
    openContainerManagement(container) {
      this.container = container;
      this.containerManagementModal = true;
    },
    containerSourceChanged(id) {
      this.existingPallets = true;
      // this.confirmDocumentChangeModal = true;
      setTimeout(()=>{
          this.params.refreshContainer(id);
        }, 250)
    },
    refreshContainer() {
      this.params.refreshContainerContent(this.container.id);
    },
    async resetTasks(type = false) {
      this.savingDataSource = true;
      if (type) {
        await this.$API.resetTaskStatus(this.container.id, this.shipment.id);
      }
      this.savingDataSource = false
      this.confirmDocumentChangeModal = false;
      setTimeout(()=>{
          this.params.refreshContainer(this.container.id);
        }, 250)
    },
    async saveDeckNumber() {
      this.savingDeckNo = true;
      let result = await this.$API.updateContainer({
        id: this.container.id,
        containerNo: this.container.containerNo,
      });
      this.deckModal = false;
      this.container = {};
      this.savingDeckNo = false;
    },
    async saveInvoice() {
      this.savingInvoice = true;
      let result = await this.$API.updateContainer({
        id: this.invoiceItem.id,
        invoiceNo: this.invoiceItem.invoiceNo,
        invoiceDate: this.invoiceItem.invoiceDate,
      });
      this.invoiceModal = false;
      this.invoiceItem = {};
      this.savingInvoice = false;
    },
    async saveContainer() {
      this.savingContainer = true;
      await this.$API.updateContainer(this.container);
      this.containerModal = false;
      this.container = {};
      this.savingContainer = false;
    },
    
    async updateDataSource(resetTasks = false) {
      try {
        this.savingDataSource = true;
        let active = this.availableSources.filter((x) => x.active);
        let obj = active.map((x) => ({
          source: x.type,
          type: x.type,
          id: x.id,
          sourceType: x.sourceType,
          contents:
            x.sourceType == "stockFileContainer"
              ? x.stockFileContainers[0].contents
              : x.contents,
        }));
        await this.$API.updateDocumentSource({
          bookingContainerId: this.container.id,
          source: obj,
        });
        if (resetTasks) {
          await this.$API.resetTaskStatus(this.container.id, this.shipment.id);
        }
        this.confirmDocumentChangeModal = false;
        this.dataSourceChange = false;
        this.savingDataSource = false;
        setTimeout(()=>{
          this.params.refreshContainer(this.container.id);
        }, 250)
        
        this.availableSources = [];
        this.containerModal = false;
        this.$message.success("Successfully updated data source!");
      } catch (e) {
        console.log(e);
        this.$message({
          type: "error",
          message: "There was an error changing the document source.",
        });
        this.savingDataSource = false;
      }
      // });
    },
    async updateSelectedContainerProduct(item) {
      let find = this.container.containerProducts.findIndex(
        (x) => x.id == this.containerProduct.id
      );
      this.container.containerProducts[find] = item;
      this.containerProductModal = false;
      if (item.id) {
        await this.$API.updateShipmentContainerProduct(item);
      } else {
        item = {
          ...item,
          shipmentId: this.shipment.id,
          bookingContainerId: this.container.id,
          productId: item.product.id,
        };
        let result = await this.$API.createShipmentContainerProduct(item);
        this.container.containerProducts.push({ ...item, ...result });
      }
      this.containerProduct = {};
      console.log(this.params);
      this.params.refreshContainers();
      this.$message.success("Successfully updated container product!");
    },
    async updateStockSealNo() {
      this.sealLoader = true;
      let result = await this.$API.updateContainer({
        id: this.selectedContainer.id,
        stockSealNo: this.selectedContainer.stockSealNo,
      });
      this.sealLoader = false;
      this.sealStockDialog = false;
    },
    viewContainerModal(item) {
      this.existingPallets = false;
      this.container = item;
      this.getAvailableSources();
      this.containerModal = true;
    },
    viewSource(item) {
      this.sourceItem = item;
      if (this.sourceItem.sourceType == "stockFileContainer") {
        this.selectedContainer = this.sourceItem.stockFileContainers[0];
      }
      this.sourceModal = true;
    },
  },
};
</script>